// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import FetchApi from "./ApiCal";


// import '../../public/firebase-messaging-sw'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxwzbehjrT3hVubyS9VWBPBTvglagBDv0",
  authDomain: "instaguard-demo.firebaseapp.com",
  databaseURL: "https://instaguard-demo-default-rtdb.firebaseio.com",
  projectId: "instaguard-demo",
  storageBucket: "instaguard-demo.appspot.com",
  messagingSenderId: "729845483920",
  appId: "1:729845483920:web:966070371105b449420b15",
  measurementId: "G-ZNDLWD9NCG"
};//demo

// const firebaseConfig = {
//   apiKey: "AIzaSyDMhBHRvJTKDYOB7aT6bcB_n37FxKNVPQc",
//   authDomain: "instaguard-live.firebaseapp.com",
//   databaseURL: "https://instaguard-live-default-rtdb.firebaseio.com",
//   projectId: "instaguard-live",
//   storageBucket: "instaguard-live.appspot.com",
//   messagingSenderId: "89712274963",
//   appId: "1:89712274963:web:6800c69975e72fb54419e6",
//   measurementId: "G-531L6J68Z9"
// };//live

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebase = getDatabase(app);
const analytics = getAnalytics(app);
const auth = getAuth();
export var messaging = null;
if ('serviceWorker' in window.navigator) {
  messaging = (function () {
    return getMessaging(app)
  })()

  console.log('message ON');
} else {
  console.log('message Off');
}
// isSupported().then(async is => {
//   if (is) {
//     console.log(is, 'jhshshhsgtghdghtgj');

//     messaging = await getMessaging(app);
//   }
// })
// console.log(app, 'fdwfwf');
let email = "test@yopmail.com";
let password = "test@123";
signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;

    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (messaging != null) {
      onMessage(messaging, (payload) => {
        console.log("payload", payload)
        resolve(payload);
      });
    } else (
      reject('empty')
    )

  });

// const requestPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//       getTokene();
//     } else {
//       console.log('Unable to get permission for notifications.');
//     }
//   } catch (error) {
//     console.error('Error requesting permission:', error);
//   }

export const requestForToken = async (id) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('Notification permission granted.');
      if (messaging != null) {
        return await getToken(messaging, { vapidKey: 'BNbuq5vzZf0_STAIqHZyn6rpX9ZKwegfhLX3tTomJHcQMreTn3VtHZ3OH0-je_VpB23JD2KG1fnF3GWU3o9gnlw' })
          .then((currentToken) => {
            if (currentToken) {
              FetchApi('DeviceTokenWeb', {
                device_token: currentToken,
                id,
                mode: 'login'
              })
              console.log('current token for client: ', currentToken);
              // Perform any other neccessary action with the token
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      }

      // getTokene();
    } else {
      console.log('Unable to get permission for notifications.');
    }
  } catch (error) {
    console.error('Error requesting permission:', error);
  }


};
